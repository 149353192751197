<template>
  <div class="row">
    <div class="col-12">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body" style="padding: 2.5rem">
          <div class="text-center">
            <h2>
              <span>Create Profile</span>
              <h6 class="heading-small text-muted py-3text-truncate">
                <span>Help us get to know you better!</span>
              </h6>
            </h2>
          </div>
          <br/>

          <form role="form">
            <h6 class="heading-small text-muted text-truncate">
              <span>Personal Info</span>
            </h6>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-4 col-lg-4"
                   v-if="fieldSettings && fieldSettings.first_name && fieldSettings.first_name.is_visible">
                <base-input label="First Name" :required="isMandatory('first_name')">
                  <a-input v-model="formData.first_name" :disabled="lockForm"/>
                  <div v-if="isMandatory('first_name')">
                    <div
                        v-if="$v.formData.first_name && $v.formData.first_name.$dirty"
                        class="text-danger"
                    >
                      <div class="error" v-if="$v.formData.first_name.$invalid">
                        Field is required
                      </div>
                    </div>
                  </div>
                </base-input>
              </div>
              <div class="col-12 col-sm-12 col-md-3 col-lg-3"
                   v-if="fieldSettings &&  fieldSettings.middle_name.is_visible">
                <base-input label="Middle Name" :required="isMandatory('middle_name')">
                  <a-input
                      v-model="formData.middle_name"
                      :disabled="lockForm"
                  />
                  <div v-if="isMandatory('middle_name')">
                    <div
                        v-if="$v.formData.middle_name && $v.formData.middle_name.$dirty"
                        class="text-danger"
                    >
                      <div
                          class="error"
                          v-if="!$v.formData.middle_name.required"
                      >
                        Field is required
                      </div>
                    </div>
                  </div>
                </base-input>
              </div>

              <div class="col-12 col-sm-12 col-md-4 col-lg-4"
                   v-if="fieldSettings &&  fieldSettings.last_name.is_visible">
                <base-input label="Last Name" :required="isMandatory('last_name')">
                  <a-input v-model="formData.last_name" :disabled="lockForm"/>
                  <div v-if="isMandatory('middle_name')">
                    <div
                        v-if="$v.formData.last_name && $v.formData.last_name.$dirty"
                        class="text-danger"
                    >
                      <div
                          class="error"
                          v-if="!$v.formData.last_name.required"
                      >
                        Field is required
                      </div>
                    </div>
                  </div>
                </base-input>
              </div>

              <div class="col-12 col-sm-12 col-md-1 col-lg-1"
                   v-if="fieldSettings && fieldSettings.last_name_suffix && fieldSettings.last_name_suffix.is_visible">
                <base-input label="Suffix" :required="isMandatory('last_name_suffix')">
                  <a-input
                      v-model="formData.last_name_suffix"
                      :disabled="lockForm"
                  />
                  <div v-if="isMandatory('last_name_suffix')">
                    <div
                        v-if="$v.formData.last_name_suffix && $v.formData.last_name_suffix.$dirty"
                        class="text-danger"
                    >
                      <div
                          class="error"
                          v-if="!$v.formData.last_name_suffix.required"
                      >
                        Field is required
                      </div>
                    </div>
                  </div>
                </base-input>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-sm-12 col-md-5 col-lg-5">
                <base-input label="Birthday">
                  <div class="row">
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4"
                         v-if="fieldSettings.birth_month.is_visible">
                      <a-select v-model="birthMonth" @change="populateDaysSelection" :disabled="lockForm"
                                :required="isMandatory('birth_month')">
                        <a-select-option
                            v-for="month in options.months"
                            :key="month.key"
                            :value="month.key"
                        >
                          {{ month.name }}
                        </a-select-option>
                      </a-select>
                      <small class="text-muted"><i>Month</i>
                        <span class="text-danger" v-if="isMandatory('birth_month')">*</span>
                        <br>
                        <div v-if="isMandatory('birth_month')">
                          <div v-if="$v.formData.birth_month && $v.formData.birth_month.$dirty"
                               class="text-danger">
                            <div class="error" v-if="!$v.formData.birth_month.required">
                              Field is required
                            </div>
                          </div>
                        </div>
                      </small>
                    </div>
                    /
                    <div class="col-4 col-sm-4 col-md-3 col-lg-3"
                         v-if="fieldSettings.birth_day.is_visible">
                      <a-select v-model="birthDay" :disabled="lockForm" :required="isMandatory('birth_day')">
                        <a-select-option
                            v-for="day in options.days"
                            :key="day"
                            :value="day"
                        >
                          {{ day }}
                        </a-select-option>
                      </a-select>
                      <small class="text-muted"><i>Day</i>
                        <span class="text-danger" v-if="isMandatory('birth_day')">*</span>
                        <br>
                        <div v-if="isMandatory('birth_day')">
                          <div v-if="$v.formData.birth_day &&  $v.formData.birth_day.$dirty"
                               class="text-danger">
                            <div class="error" v-if="!$v.formData.birth_day.required">
                              Field is required
                            </div>
                          </div>
                        </div>
                      </small>
                    </div>
                    /
                    <div class="col-4 col-sm-4 col-md-4 col-lg-4"
                         v-if="fieldSettings.birth_year.is_visible">
                      <a-select v-model="birthYear" @change="populateDaysSelection" :disabled="lockForm"
                                :required="isMandatory('birth_year')">
                        <a-select-option
                            v-for="year in 101"
                            :key="parseInt(yearStart) + parseInt(year)"
                            :value="parseInt(yearStart) + parseInt(year)"
                        >
                          {{ parseInt(yearStart) + parseInt(year) }}
                        </a-select-option>
                      </a-select>
                      <small class="text-muted">
                        <i>Year</i>
                        <span class="text-danger" v-if="isMandatory('birth_year')">*</span>
                        <br>
                        <div v-if="isMandatory('birth_year')">
                          <div v-if="$v.formData.birth_year && $v.formData.birth_year.$dirty"
                               class="text-danger">
                            <div class="error" v-if="!$v.formData.birth_year.required">
                              Field is required
                            </div>
                          </div>
                        </div>
                      </small>
                    </div>
                  </div>
                </base-input>
              </div>

              <div class="col-12 col-sm-12 col-md-4 col-lg-4"
                   v-if="fieldSettings &&  fieldSettings.gender &&  fieldSettings.gender.is_visible">
                <base-input label="Gender" :required="isMandatory('gender')">
                  <br/>
                  <a-select v-model="formData.gender" :disabled="lockForm">
                    <a-select-option
                        v-for="gender in options.genders"
                        :key="gender.key"
                        :value="gender.key"
                    >
                      {{ gender.name }}
                    </a-select-option>
                  </a-select>
                  <div v-if="isMandatory('gender')">
                    <div v-if="$v.formData.gender &&  $v.formData.gender.$dirty" class="text-danger">
                      <div class="error" v-if="!$v.formData.gender.required">
                        Field is required
                      </div>
                    </div>
                  </div>
                </base-input>
              </div>

              <div class="col-12 col-sm-12 col-md-3 col-lg-3"
                   v-if="fieldSettings && fieldSettings.nick_name && fieldSettings.nick_name.is_visible">
                <base-input label="Nickname" :required="isMandatory('nick_name')">
                  <a-input v-model="formData.nick_name" :disabled="lockForm"/>
                  <div v-if="isMandatory('nick_name')">
                    <div
                        v-if="$v.formData.nick_name && $v.formData.nick_name.$dirty"
                        class="text-danger"
                    >
                      <div
                          class="error"
                          v-if="!$v.formData.nick_name.required"
                      >
                        Field is required
                      </div>
                    </div>
                  </div>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-4 col-lg-4">
                <base-input label="Password" required>
                  <a-input-password
                      placeholder="Enter Password"
                      :disabled="lockForm"
                      v-model="formData.password"
                  />
                  <div v-if="$v.formData.password.$dirty" class="text-danger">
                    <div class="error" v-if="!$v.formData.password.required">
                      Field is required
                    </div>
                  </div>
                </base-input>
              </div>
            </div>

            <div
                v-if="
                                (fieldSettings &&
                                  fieldSettings.location_region &&
                                  fieldSettings.location_region.is_visible) ||
                                  (fieldSettings.location_barangay &&
                                  fieldSettings.location_barangay.is_visible) ||
                                (fieldSettings.location_unit &&
                                fieldSettings.location_unit.is_visible &&
                                fieldSettings.location_street.is_visible)">
              <hr/>
              <h6 class="heading-small text-muted text-truncate">
                <span>Address</span>
              </h6>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-3 col-lg-3"
                     v-if="fieldSettings && fieldSettings.location_unit &&
                          fieldSettings.location_unit.is_visible &&
                            fieldSettings.location_street.is_visible
                        ">
                  <base-input label="Unit No.">
                    <a-input
                        v-model="formData.location_unit"
                        :disabled="lockForm"
                    />
                    <div v-if="isMandatory('location_unit')">
                      <div
                          v-if="$v.formData.location_unit && $v.formData.location_unit.$dirty"
                          class="text-danger"
                      >
                        <div
                            class="error"
                            v-if="!$v.formData.location_unit.required"
                        >
                          Field is required
                        </div>
                      </div>
                    </div>
                  </base-input>
                </div>
                <div class="col-12 col-sm-12 col-md-9 col-lg-9"
                     v-if="fieldSettings && fieldSettings.location_street &&
                          fieldSettings.location_street.is_visible &&
                            fieldSettings.location_street.is_visible
                        ">
                  <base-input label="Street" :required="isMandatory('location_street')">
                    <a-input
                        v-model="formData.location_street"
                        :disabled="lockForm"
                    />
                    <div v-if="isMandatory('location_street')">
                      <div
                          v-if="$v.formData.location_street && $v.formData.location_street.$dirty"
                          class="text-danger"
                      >
                        <div
                            class="error"
                            v-if="!$v.formData.location_street.required"
                        >
                          Field is required
                        </div>
                      </div>
                    </div>
                  </base-input>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-sm-12 col-md-3 col-lg-3"
                     v-if="fieldSettings && fieldSettings.location_region &&  fieldSettings.location_region.is_visible">
                  <base-input label="Region" :required="isMandatory('location_region')">
                    <br/>
                    <a-select
                        v-model="formData.region_code"
                        @change="onChangeRegion"
                        :disabled="lockForm"
                    >
                      <a-select-option
                          v-for="region in options.regions"
                          :key="region.code"
                          :value="region.code"
                      >
                        {{ region.regionName }} - {{ region.name }}
                      </a-select-option>
                    </a-select>
                    <div v-if="isMandatory('location_region')">
                      <div v-if="$v.formData.location_region && $v.formData.location_region.$dirty"
                           class="text-danger">
                        <div class="error" v-if="!$v.formData.location_region.required">
                          Field is required
                        </div>
                      </div>
                    </div>
                  </base-input>
                </div>
                <div class="col-12 col-sm-12 col-md-3 col-lg-3" v-if="fieldSettings && fieldSettings.location_province &&
                            fieldSettings.location_province.is_visible &&
                              !isRegionNCR
                          ">
                  <base-input label="Province" :required="isMandatory('location_province')">
                    <br/>
                    <a-select
                        v-model="formData.province_code"
                        @change="onChangeProvince"
                        :disabled="lockForm"
                    >
                      <a-select-option
                          v-for="province in options.provinces"
                          :key="province.code"
                          :value="province.code"
                      >
                        {{ province.name }}
                      </a-select-option>
                    </a-select>
                    <div v-if="isMandatory('location_province')">
                      <div
                          v-if="$v.formData.location_province && $v.formData.location_province.$dirty"
                          class="text-danger"
                      >
                        <div
                            class="error"
                            v-if="!$v.formData.location_province.required"
                        >
                          Field is required
                        </div>
                      </div>
                    </div>
                  </base-input>
                </div>
                <div class="col-12 col-sm-12 col-md-3 col-lg-3"
                     v-if="fieldSettings && fieldSettings.location_city && fieldSettings.location_city.is_visible">
                  <base-input label="City" :required="isMandatory('location_city')">
                    <br/>
                    <a-select
                        v-model="formData.city_code"
                        @change="onChangeCity"
                        :disabled="lockForm"
                    >
                      <a-select-option
                          v-for="city in options.cities"
                          :key="city.code"
                          :value="city.code"
                      >
                        {{ city.name }}
                      </a-select-option>
                    </a-select>
                    <div v-if="isMandatory('location_city')">
                      <div
                          v-if="$v.formData.municity && $v.formData.municity.$dirty"
                          class="text-danger"
                      >
                        <div class="error" v-if="$v.formData.municity.$invalid">
                          Field is required
                        </div>
                      </div>
                    </div>
                  </base-input>
                </div>
                <div class="col-12 col-sm-12 col-md-3 col-lg-3"
                     v-if="fieldSettings && fieldSettings.location_barangay && fieldSettings.location_barangay.is_visible">
                  <base-input label="Barangay" :required="isMandatory('location_barangay')">
                    <br/>
                    <a-select
                        v-model="formData.barangay_code"
                        @change="onChangeBarangay"
                        :disabled="lockForm"
                    >
                      <a-select-option
                          v-for="barangay in options.barangays"
                          :key="barangay.code"
                          :value="barangay.code"
                      >
                        {{ barangay.name }}
                      </a-select-option>
                    </a-select>
                    <div v-if="isMandatory('location_barangay')">
                      <div
                          v-if="$v.formData.location_barangay && $v.formData.location_barangay.$dirty"
                          class="text-danger"
                      >
                        <div class="error" v-if="$v.formData.location_barangay.$invalid">
                          Field is required
                        </div>
                      </div>
                    </div>
                  </base-input>
                </div>
              </div>
            </div>
            <hr/>
            <h6 class="heading-small text-muted text-truncate">
              <span>Contact Details</span>
            </h6>
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                <base-input label="Mobile Number" :required="isMandatory('mobile_no')">
                  <a-input-group compact>
                    <a-select
                        default-value="+63"
                        :style="this.windowWidth < 768 ? {width:'30%'}: {width:'15%'}"
                        v-model="countryCode"
                        :disabled="lockForm || selfRegistration"
                    >
                      <a-select-option value="+63">
                        +63
                      </a-select-option>
                    </a-select>
                    <a-input
                        v-model="mobileNo"
                        :style="this.windowWidth < 768 ? {width:'70%'}: {width:'85%'}"
                        placeholder="9XXXXXXXXX"
                        :disabled="lockForm || selfRegistration"
                    />
                  </a-input-group>
                  <div v-if="isMandatory('mobile_no')">
                    <div v-if="$v.formData.mobile_no.$dirty" class="text-danger">
                      <div class="error" v-if="!$v.formData.mobile_no.required">
                        Field is required
                      </div>
                      <div
                          class="error"
                          v-else-if="!$v.formData.mobile_no.mobileNoFormat"
                      >
                        Invalid mobile number format. Use +639XXXXXXXXX format.
                      </div>
                    </div>
                  </div>
                </base-input>
              </div>
              <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                <base-input label="Email Address" :required="isMandatory('email')">
                  <a-input v-model="formData.email" :disabled="lockForm"/>
                  <div v-if="isMandatory('email')">
                    <div
                        v-if="$v.formData.email && $v.formData.email.$dirty"
                        class="text-danger"
                    >
                      <div class="error" v-if="$v.formData.email.$invalid">
                        Field is required
                      </div>
                    </div>
                  </div>
                </base-input>
              </div>
            </div>

            <hr/>

            <div class="row">
              <div class="col-12">
                <!-- <a-checkbox v-model="dpcConsent"> -->
                <span
                >By signing in, you agree to the
                  <a href="javascript:void(0)'" v-b-modal.modalDpc
                  >Data Privacy Policy</a
                  >
                  of our company.</span
                >
                <!-- </a-checkbox> -->
                <div v-if="$v.formData.dpc_consent.$dirty" class="text-danger">
                  <div class="error" v-if="!$v.formData.dpc_consent.required">
                    Field is required
                  </div>
                  <div class="error" v-if="!$v.formData.dpc_consent.mustBeTrue">
                    Must accept company's data privacy policy.
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <div class="text-right">
              <b-button
                  variant="primary"
                  @click="createProfile"
                  :disabled="formSubmitting || lockForm"
              >
                <span v-if="!formSubmitting">Create Profile</span>
                <span v-else><b-spinner small></b-spinner> Processing ...</span>
              </b-button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <b-modal id="modalDpc" title="Data Privacy Policy" ok-only size="lg">
      <PdfViewer :pdfUrl="dataPrivacyFilePath"/>
      <div class="row">
        <div class="col-12">
          <a-checkbox v-model="dpcConsent" v-b-modal.modalDpc>
            <span
            >By signing in, you agree to the
              <a href="javascript:void(0)'" v-b-modal.modalDpc
              >Data Privacy Policy</a
              >
              of our company.</span
            >
          </a-checkbox>

          <div v-if="$v.formData.dpc_consent.$dirty" class="text-danger">
            <div class="error" v-if="!$v.formData.dpc_consent.required">
              Field is required
            </div>
            <div class="error" v-if="!$v.formData.dpc_consent.mustBeTrue">
              Must accept company's data privacy policy.
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import PSGCApi from '../../controllers/psgcApi';
import HTTPRequest from '../../controllers/httpRequest';
import {email, required} from 'vuelidate/lib/validators';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import {Base64} from 'js-base64';
import PdfViewer from '@/components/PdfViewer.vue';
import secureStorage from '../../controllers/secureStorage';

const ENC_SECRET = process.env.VUE_APP_ENC_SECRET;

export default {
  name: 'SelfRegistrationForm',
  props: {
    clpSitePageSettings: Object
  },
  components: {
    PdfViewer
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      countryCode: '+63',
      mobileNo: null,
      fieldSettings: null,
      mandatoryFields: null,
      formData: {
        first_name: '',
        middle_name: '',
        last_name: '',
        password: '',
        last_name_suffix: '',
        nick_name: '',
        mobile_no: '',
        email: '',
        location_unit: '',
        location_street: '',
        location_barangay: '',
        barangay_code: null,
        location_city: '',
        city_code: null,
        location_province: '',
        province_code: null,
        location_region: '',
        region_code: null,
        location_country: 'PH',
        gender: null,
        birth_month: null,
        birth_year: null,
        birth_day: null,
        dpc_consent: false
      },
      options: {
        genders: [
          {key: 'M', name: 'Male'},
          {key: 'F', name: 'Female'}
        ],
        months: [
          {key: '01', name: 'January'},
          {key: '02', name: 'February'},
          {key: '03', name: 'March '},
          {key: '04', name: 'April '},
          {key: '05', name: 'May '},
          {key: '06', name: 'June'},
          {key: '07', name: 'July '},
          {key: '08', name: 'August '},
          {key: '09', name: 'September'},
          {key: '10', name: 'October'},
          {key: '11', name: 'November'},
          {key: '12', name: 'December'}
        ],
        days: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
        regions: [],
        provinces: [],
        cities: [],
        barangays: []
      },
      birthMonth: null,
      birthYear: null,
      birthDay: null,
      yearStart: moment().year() - 101,
      ENC_SECRET,
      formSubmitting: false,
      selfRegistration: false,
      lockForm: false,
      dataPrivacyFilePath: null
    };
  },
  validations() {
    const _fieldSettings = this.clpSitePageSettings.field_settings;
    this.fieldSettings = JSON.parse(_fieldSettings);
    console.log('----this.fieldSettings-------', this.fieldSettings)
    this.mandatoryFields = this.fieldSettings;
    const validation = {
      formData: {
        password: {
          required
        },
        dpc_consent: {
          required,
          mustBeTrue(value) {
            return value;
          }
        }
      }
    };

    if (this.fieldSettings != null) {
      if (this.fieldSettings.last_name_suffix.is_required == true) {
        validation.formData['last_name_suffix'] = {
          required
        };
      }
      if (this.fieldSettings.first_name.is_required == true) {
        validation.formData['first_name'] = {
          required
        };
      }
      if (this.fieldSettings.last_name.is_required == true) {
        validation.formData['last_name'] = {
          required
        };
      }
      if (this.fieldSettings.middle_name.is_required == true) {
        validation.formData['middle_name'] = {
          required
        };
      }
      if (this.fieldSettings.email.is_required == true) {
        validation.formData['email'] = {required, email};
      }

      if (this.fieldSettings.gender.is_required == true) {
        validation.formData['gender'] = {required};
      }
      if (this.fieldSettings.nick_name.is_required == true) {
        validation.formData['nick_name'] = {required};
      }

      if (this.fieldSettings.location_city.is_required == true) {
        validation.formData['municity'] = {required};
      }

      if (this.mandatoryFields.location_region.is_required) {
        validation.formData['location_region'] = {required};
      }
      if (this.mandatoryFields.location_barangay.is_required) {
        validation.formData['barangay_code'] = {required};
        validation.formData['location_barangay'] = {required};
      }
      if (this.mandatoryFields.location_street.is_required) {
        validation.formData['location_street'] = {required};
      }
      if (this.mandatoryFields.location_unit.is_required) {
        validation.formData['location_unit'] = {required};
      }
      if (this.mandatoryFields.location_province.is_required) {
        validation.formData['province_code'] = {required};
        validation.formData['location_province'] = {required};
      }

      if (this.fieldSettings.birth_month.is_required == true) {
        validation.formData['birth_month'] = {required};
      }

      if (this.fieldSettings.birth_day.is_required == true) {
        validation.formData['birth_day'] = {required};
      }

      if (this.fieldSettings.birth_year.is_required == true) {
        validation.formData['birth_year'] = {required};
      }

      if (this.fieldSettings.zipcode.is_required == true) {
        validation.formData['zipcode'] = {
          required,
          numeric: (value) => /^\d+$/.test(value)
        };
      }

      if (this.fieldSettings.mobile_no.is_required == true) {
        if (this.fieldSettings.allow_null_mobile_no == true && this.formData.allow_null_mobile_no == true) {
          validation.formData['mobile_no'] = {
            mobileNoFormat(value) {
              const regex = /^(\+639)\d{9}$/;
              return regex.test(value);
            }
          };
        } else {
          validation.formData['mobile_no'] = {
            required,
            mobileNoFormat(value) {
              const regex = /^(\+639)\d{9}$/;
              return regex.test(value);
            }
          }
        }
      }
    }
    return validation;
  },
  methods: {
    isMandatory(key) {
      if (this.mandatoryFields != null) {
        if (this.mandatoryFields[key] != undefined) {
          if (key == 'mobile_no' && this.mandatoryFields['allow_null_mobile_no']) {
            return false
          }
          return this.mandatoryFields[key].is_required;
        } else {
          return false;
        }
      }
      return false;
    },
    updateWidth() {
      this.windowWidth = window.innerWidth;
    },
    async createProfile() {
      this.$v.formData.$touch();

      if (!this.dpcConsent) {
        this.$bvModal.show('modalDpc');
        return;
      }

      if (!this.$v.formData.$invalid && !this.lockForm) {
        this.formSubmitting = true;
        if (this.formData.email == '' || this.formData.email == null) {
          delete this.formData.email;
        }
        this.formData.membership_program_id = this.clpSitePageSettings.membership_program_id;
        const passwordData = this.formData.password;
        delete this.formData.password;
        const response = await HTTPRequest.postRequest('/api/v2/clp_members/', {
          member: this.formData,
          'password': passwordData,
          create_member_account: true,
          site_page: {
            id: this.clpSitePageSettings.id
          }
        });

        if (response.data && response.data.id) {
          this.$notification.success({
            message: 'Profile has been created.'
          });

          secureStorage.setItem('clpSitePageAccessToken', response.access_token);
          secureStorage.setItem('new_member_id', response.data.id);
          setTimeout(() => {
            this.$router.push({
              name: 'AccountVerification',
              params: {siteName: this.clpSitePageSettings.siteName}
            });
          }, 1000);
        } else {
          if (response.error != undefined) {
            this.$notification.error({
              message: response.error
            });
          } else {
            this.$notification.error({
              message: 'Something went wrong. Please try again.'
            });
          }
        }

        this.formSubmitting = false;
      }
    },

    async getRegions() {
      this.options.regions = await PSGCApi.getRegions();
    },

    async getProvinces(regionCode) {
      this.isRegionNCR = regionCode == PSGCApi.NCR_REGION_CODE ? true : false;
      this.options.provinces = await PSGCApi.getProvinces(regionCode);
    },

    async getCities(provinceCode) {
      this.options.cities = await PSGCApi.getCities(provinceCode);
    },

    async getBarangays(cityCode) {
      this.options.barangays = await PSGCApi.getBarangays(cityCode);
    },

    zeroPad(num, places) {
      return String(num).padStart(places, '0');
    },

    populateDaysSelection() {
      const month = this.birthMonth != null ? this.birthMonth : 1;
      const year = this.birthYear != null ? this.birthYear : this.yearStart;
      const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
      const days = [];
      for (let i = 1; i <= daysInMonth; i++) {
        days.push(moment(`${year}-${month}-${i}`, 'YYYY-MM-DD').format('DD'));
      }
      if (this.birthDay && !days.includes(this.birthDay)) {
        this.birthDay = null;
      }
      this.options.days = days;
    },
    onChangeRegion() {
      this.formData.location_region = event.target.innerText;
      this.formData.province_code = null;
      this.formData.location_province = null;
      this.formData.city_code = null;
      this.formData.location_city = null;
      this.formData.barangay_code = null;
      this.formData.location_barangay = null;
      this.getProvinces(this.formData.region_code);
    },

    onChangeProvince() {
      this.formData.location_province = event.target.innerText;
      this.formData.city_code = null;
      this.formData.location_city = null;
      this.formData.barangay_code = null;
      this.formData.location_barangay = null;
      this.getCities(this.formData.province_code);
    },

    onChangeCity() {
      this.formData.location_city = event.target.innerText;
      this.formData.barangay_code = null;
      this.formData.location_barangay = null;
      this.getBarangays(this.formData.city_code);
    },

    onChangeBarangay() {
      this.formData.location_barangay = event.target.innerText;
    }
  },

  created() {
    this.getRegions();
    const _fieldSettings = this.clpSitePageSettings.field_settings;
    this.fieldSettings = JSON.parse(_fieldSettings);
    this.mandatoryFields = this.fieldSettings;
  },
  beforeUnmount() {
    // Clean up the event listener
    window.removeEventListener('resize', this.updateWidth);
  },
  mounted() {
    window.addEventListener('resize', this.updateWidth);
    console.log(this.$route.query);
    if (this.$route.query.token !== undefined) {
      try {
        const b64decode = Base64.decode(this.$route.query.token);
        const tokenDecrypt = CryptoJS.AES.decrypt(
            b64decode,
            ENC_SECRET
        ).toString(CryptoJS.enc.Utf8);
        const tokenObj = JSON.parse(tokenDecrypt);
        const validity = tokenObj.valid_at;
        const mobileNumber = tokenObj.mobile;
        const now = moment().format('YYYY-MM-DD HH:mm');

        if (validity < now) {
          this.$notification.error({
            message: 'Token Expired.'
          });
          this.selfRegistration = false;
          this.lockForm = true;
        } else {
          this.formData.mobile_no = mobileNumber;
          this.selfRegistration = true;
          this.lockForm = false;
        }
      } catch (error) {
        console.log('Self Registration Error: ', error);
        this.$notification.error({
          message: 'Invalid Token.'
        });
        this.selfRegistration = false;
        this.lockForm = true;
      }
    }

    this.dataPrivacyFilePath = '/' + this.clpSitePageSettings.policy.filePath;
  },

  computed: {
    dpcConsent: {
      get() {
        return this.formData.dpc_consent ? true : false;
      },
      set(val) {
        this.formData.dpc_consent = val;
      }
    }
  },

  watch: {
    birthMonth: function (month) {
      if (month != null) {
        let year = moment().year();
        let day = moment().day();
        this.formData.birth_month = moment(`${year}-${month}-${day}`).format(
            'YYYY-MM-DD'
        );
      } else {
        this.formData.birth_month = null;
      }
    },

    birthYear: function (year) {
      if (year != null) {
        let month = moment().month();
        let day = moment().day();
        this.formData.birth_year = moment(`${year}-${month}-${day}`).format(
            'YYYY-MM-DD'
        );
      } else {
        this.formData.birth_year = null;
      }
    },

    birthDay: function (day) {
      if (day != null) {
        let month = moment().month();
        let year = moment().year();
        this.formData.birth_day = moment(`${year}-${month}-${day}`).format(
            'YYYY-MM-DD'
        );
      } else {
        this.formData.birth_day = null;
      }
    },

    mobileNo: function (mobile) {
      if (mobile != null) {
        this.formData.mobile_no = this.countryCode + mobile;
      } else {
        this.formData.mobile_no = null;
      }
    }
  }
};
</script>
